import React, { useState, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import jaLocale from '@fullcalendar/core/locales/ja';
import './calendar.css';


const Calender = ({ setCurrentApp, handleHomeClick }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [eventDetails, setEventDetails] = useState(null); //クリックされたイベントの詳細を保持
  const [isLoading, setIsLoading] = useState(false); // ローディング状態を管理
  

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

// FullCalendarの設定をメモ化
const calendarConfig = useMemo(() => ({
  plugins: [dayGridPlugin, googleCalendarPlugin],
  initialView: 'dayGridMonth',
  googleCalendarApiKey: 'AIzaSyDrss6GG4TRhI1untgpLGw5NUAhAQ7vG10',
  eventSources: [
    {
      googleCalendarId: '5995742c6ac3d98c465b7ea7d632a42ac48add93751f938ac8e6b8303fb50e15@group.calendar.google.com',
      color: '#ffcccc', // Myカレンダーの色
    },
    {
      googleCalendarId: 'ja.japanese#holiday@group.v.calendar.google.com',
      color: '#c0c0c0', // 日本の祝日カレンダーの色
    },
  ],
  locale: jaLocale,
  editable: false,
  headerToolbar: {
    left: 'title',
    center: 'today', // 今日ボタンを追加
    right: 'prev,next', // 既存の前月・次月ボタン
  },
  showNonCurrentDates: false,
  firstDay: 1,
  contentHeight: 'auto',
  dayCellContent: (e) => {
    e.dayNumberText = e.dayNumberText.replace("日", "");
    return e.dayNumberText;
  },
  eventTimeFormat: { hour: 'numeric', minute: '2-digit' },
  eventClick: (info) => {
    info.jsEvent.preventDefault(); // デフォルトの動作（リンク遷移など）を無効化
    setEventDetails(info.event); // クリックされたイベントの詳細をステートにセット
  },
  // イベント描画時のフック
  eventDidMount: (info) => {
    // タイトルが「！」で終わる場合
    if (info.event.title.endsWith('！')) {
      info.el.style.backgroundColor = '#FF0000'; // 背景を赤色に変更
      info.el.style.borderColor = '#FF0000'; // 枠線も赤色に
      info.el.style.color = '#FFFFFF'; // テキスト色を白に変更
    }
  },
  // ローディングのイベントハンドラ
  loading: (isLoading) => {
    setIsLoading(isLoading); // ローディング状態を更新
  }
}), []); // 初期化時に一度だけ設定


  // ポップアップを閉じる処理
  const closePopup = () => setEventDetails(null);

  return (
    <div className={`calender-container ${isAnimating ? 'apphomeback' : ''}`}>
      <div className="calendar-body">
        {/* FullCalendarのローディング状態によってぐるぐるを表示 */}
          {isLoading && <div className="loading-spinner">⏳ ローディング中...</div>}        
        <FullCalendar {...calendarConfig} />
      </div>

      {/* イベント詳細がある場合、ポップアップで表示 */}
      {eventDetails && (
        <div className="event-details-modal" onClick={closePopup}>
          <div className="event-details-content" onClick={(e) => e.stopPropagation()}>
            <div className="event-details-header">
              <div className="event-details-title">{eventDetails.title}</div>
              <div className="event-details-date">
                {eventDetails.start.toLocaleDateString()}（{eventDetails.start.toLocaleString('ja-JP', { weekday: 'short' })}）
              </div>
            </div>
            <div className="event-details-description">
              <svg className="menu-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="25" viewBox="0 0 30 25">
                <rect width="30" height="4" rx="2" />
                <rect y="10" width="30" height="4" rx="2" />
                <rect y="20" width="20" height="4" rx="2" />
              </svg>
              <p>{eventDetails.extendedProps.description || '詳細なし'}</p>
            {/* 追加: 場所の情報を表示 */}
            {eventDetails.extendedProps.location && (
              <p><strong>場所:</strong> {eventDetails.extendedProps.location}</p>
            )}
            </div>
            <div className="event-details-footer">
              <div className="calendar-name">Myカレンダー</div>
              <div className="event-creator">作成者: 城山大賀</div>
            </div>
            <button className="event-details-close" onClick={closePopup}>×</button>
          </div>
        </div>
      )}

      <div className="shoot-game-footer">
        <button className="home-button" onMouseDown={handleClick}></button>
      </div>
    </div>
  );
};

export default Calender;
