//Match-chat.js
import React, { useState, useContext, useEffect } from 'react';
import './Match-chat.css';
import UserList from './UserList';
import { LikedPhotosContext } from './LikedPhotosContext.js';
import { MessageContext } from '../MessageContext';
import { AppContext } from './AppContext.js';  // AppContextを利用してフラグを渡す

const MatchChat = ({ setCurrentApp, handleHomeClick }) => {
  const { setFromMatchChat, isVerified, setIsVerified } = useContext(AppContext);
  const { likedPhotos } = useContext(LikedPhotosContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const { messagesByUser, setMessagesForUser } = useContext(MessageContext);
  const [inputValue, setInputValue] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [botMessageIndexes, setBotMessageIndexes] = useState({});
  const [isUnderReview, setIsUnderReview] = useState(false);
  const [showPhotoGrid, setShowPhotoGrid] = useState(false); // 写真欄を表示するかのフラグ
  // const [messageCountByUser, setMessageCountByUser] = useState({}); // 各ユーザーのメッセージ送信回数を管理
  const [showUpgradePopup, setShowUpgradePopup] = useState(false); // ポップアップ表示状態を管理

  // 写真関連の状態
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  useEffect(() => {
    // sessionStorage.removeItem('isVerified');
    // setIsVerified(false); // 初期化    
    fetchPhotos();
  }, []);
  

  const fetchPhotos = async () => {
    try {
      console.log('Fetching photos from API');
      // 開発環境なら Pixabay API へ直接アクセス、本番では Cloudflare Workers 経由
      const apiUrl = process.env.NODE_ENV === "development"
        ? `https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API_KEY}&q=landscape&image_type=photo&per_page=30`
        : `/api/pixabay?q=landscape&image_type=photo&per_page=30`;

      const response = await fetch(apiUrl);
      const data = await response.json();
      const storedPhotos = JSON.parse(localStorage.getItem('photos')) || [];
  
      // catfish画像のリストを追加
      const catfishImages = [
        { id: 'catfish1', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish1.jpg`, date: '2024/10/28' },
        { id: 'catfish2', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish2.jpg`, date: '2024/10/28' },
        { id: 'catfish3', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish3.jpg`, date: '2024/10/28' },
        { id: 'catfish4', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish4.jpg`, date: '2024/10/28' },
        { id: 'catfish5', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish5.jpg`, date: '2024/10/28' }
      ];
  
      setPhotos([...data.hits, ...storedPhotos, ...catfishImages]); // catfish画像も含む
      console.log('Photos fetched:', [...data.hits, ...storedPhotos, ...catfishImages]);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };
  

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setShowPhotoGrid(false);
    setIsUnderReview(true);

    const reviewProcess = new Promise((resolve) => {
      setTimeout(() => {
        setIsUnderReview(false);
        resolve();
      }, 2000);
    });

    reviewProcess.then(() => {
      if (photo.previewURL.includes('DriversLicense.png')) { 
        alert('完了しました。');
        setIsVerified(true);
      } else {
        alert('本人確認に失敗しました');
        setIsVerified(false);
      }
    });
  };
  

  const handleBackClick = () => {
    setSelectedUser(null);
  };

  const renderMessage = (message) => {
    return { __html: message };
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const handleSend = () => {
    if (inputValue.trim() !== '' && selectedUser) {
      const userId = selectedUser.id;
  
      // 現在のユーザーのメッセージリストを取得し、ユーザー側のメッセージ数をカウント
      const userMessages = (messagesByUser[userId] || []).filter(message => message.user);
      const userMessageCount = userMessages.length;
  
      if (userMessageCount < 1) {
        // 初回のメッセージ送信を許可
        const newMessages = [
          ...(messagesByUser[userId] || []), 
          { text: inputValue, user: true }
        ];
        setMessagesForUser(userId, newMessages);
        setInputValue('');
  
        // ボットメッセージを送信
        setTimeout(() => {
          const botMessages = getBotMessages(selectedUser);
          const botMessageIndex = botMessageIndexes[userId] || 0;
  
          if (botMessageIndex < botMessages.length) {
            setMessagesForUser(userId, [
              ...newMessages, 
              { text: botMessages[botMessageIndex], user: false, bot: true }
            ]);
            setBotMessageIndexes({
              ...botMessageIndexes,
              [userId]: botMessageIndex + 1
            });
          }
        }, 1000);
      } else {
        // 3通目以降はポップアップを表示
        setShowUpgradePopup(true);
        setTimeout(() => setShowUpgradePopup(false), 3000); // 3秒後にポップアップを閉じる
      }
    }
  };
  

  const handleSwipeClick = () => {
    setCurrentApp('match-swipe');
    setTimeout(() => {
      const matchElement = document.querySelector('.match-swipe-body');
      if (matchElement) {
        matchElement.classList.add('slide-in-R');
        setTimeout(() => {
          matchElement.classList.remove('slide-in-R');
        }, 500);
      }
    }, 0);
  };

  const handleHome = () => {
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

  const getBotMessages = (user) => {
    const botMessagesByUser = {
      user1: [
        `こんにちは！${user.alt}です。`,
        'ごめん!旦那にアプリやってるの見られたらめんどくさいし、一旦ライン交換しよ！これ私の個人垢だからこっちでお願い',
        '<a href="https://example.com" target="_blank" rel="noopener noreferrer">https://line.me/hurin/m/</a>',
        '今日の天気はどうですか？',
        '週末の予定はありますか？',
      ],
      user2: [
        `こんにちは！${user.alt}です。`,
        '最近どうですか？',
        `${user.alt}は猫が好きです。`,
        '今週末、一緒に映画を見ませんか？',
        'こちらのリンクをチェックしてください: <a href="https://example.com" target="_blank" rel="noopener noreferrer">user2のリンク</a>'
      ],
      user3: [
        'うい',
        'へえ',
      ],
      user50: [
        'テストプレイクリアです。ありがとうございました。\n最後に、5分ほどで終わるアンケートのご協力をお願いたします！！<a href="https://docs.google.com/forms/d/e/1FAIpQLScpej7SZiA3Abr9mghTUCWR0j_UIzfXO5YR8ZTW4LtSuGygJA/viewform" target="_blank" rel="noopener noreferrer">https://docs.google.com/forms/d/e/1FAIpQLScpej7SZiA3Abr9mghTUCWR0j_UIzfXO5YR8ZTW4LtSuGygJA/viewform</a>'
      ]
    };
    return botMessagesByUser[user.id] || [];
  };

  // selectUserからsessionStorageの参照を削除
  const selectUser = (user) => {
    setSelectedUser(user);
    // 必要ならContextのisVerifiedはそのまま使う
  };

  return (
    <div className={`match-chat-container ${isAnimating ? 'home-back' : ''}`}>
      <div className="match-chat-header">
        {selectedUser ? (
            <>

            </>
          ) : null}
      </div>
      <div className="match-chat-body">
      {selectedUser ? (
          <>
            <button className="back-button" onClick={handleBackClick}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 19L8 12L15 5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              戻る
            </button>
            <div className="match-chat-title">
              {selectedUser.src && (
                <img src={selectedUser.src} alt={selectedUser.alt} className="user-photo" />
              )}
              <span className="user-name">{selectedUser.alt}</span>
            </div>
          </>
        ) : null}
        {!selectedUser ? (
          <UserList users={likedPhotos} selectUser={selectUser} isUnderReview={isUnderReview} />
        ) : (
          <>
            <div className="messages-list">
              {messagesByUser[selectedUser.id]?.map((message, index) => {
                const showDate = index === 0 || messagesByUser[selectedUser.id][index - 1].date !== message.date;
                return (
                  <div key={index}>
                    {showDate && <div className="message-date">{message.date}</div>}
                    <div className={`message ${message.user ? 'user' : message.bot ? 'bot' : ''}`} dangerouslySetInnerHTML={renderMessage(message.text)} />
                  </div>
                );
              })}
            </div>
            {isUnderReview && (
              <div className="review-message">
                <h1>現在、審査中です。</h1>
                <p style={{ fontSize: 'small' }}>審査には通常1分程度を要します</p>
              </div>
            )}
            { !isVerified && !showPhotoGrid && !isUnderReview &&(
              <div className="verification-box">
                <h1>本人確認が必要です</h1>
                <p>お客様の「本人確認有効証明」が期限を迎えました。再度本人確認書類のご提出をお願いいたします。</p>
                <button className="upload-button" onClick={() => setShowPhotoGrid(true)}>
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 2L6 8h4v8h4V8h4l-6-6zm6 18H6v2h12v-2z"/>
                  </svg>
                  書類をアップロード
                </button>
              </div>
            )}
            {showPhotoGrid && (
              <div className="photo-grid-ID">
                {photos.map(photo => (
                  <div key={photo.id} className="photo-item" onClick={() => handlePhotoClick(photo)}>
                    <img src={photo.previewURL} alt={photo.tags} />
                  </div>
                ))}
              </div>
            )}
            {isVerified && selectedUser && !isUnderReview && (
              <div className="message-input">
                <input
                  type="text"
                  placeholder="メッセージを入力"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <button onClick={() => handleSend(null)}>
                  <svg viewBox="0 0 24 24">
                    <path d="M2 21l21-9-21-9v7l15 2-15 2v7z" />
                  </svg>
                </button>
              </div>
            )}
            {showUpgradePopup && (
              <div className="upgrade-popup">
                <h1>Underプラスをゲット</h1>
                <h2>ご利用履歴があります</h2>
                <p>再度アップグレードして無制限のやりとりを楽しみましょう！</p>
                <div className="plans">
                  <div className="plan">
                    <div className="duration">12ヶ月</div>
                    <div className="price">¥787.33/月</div>
                    <div className="discount">58%割引</div>
                  </div>
                  <div className="plan">
                    <div className="duration">6ヶ月</div>
                    <div className="price">¥1,181/月</div>
                    <div className="discount">37%割引</div>
                  </div>
                  <div className="plan">
                    <div className="duration">1ヶ月</div>
                    <div className="price">¥1,889</div>
                  </div>
                </div>
                <button className="action-button">続ける</button>
                <div className="cancel-button" onClick={() => setShowUpgradePopup(false)}>結構です</div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="match-menu">
        <button className="id-icon match-button" onClick={() => setCurrentApp('match-id')}></button>
        <button className="id-swipe match-button" onClick={handleSwipeClick}></button>
        <button className="id-chat match-button" onClick={() => setCurrentApp('match-chat')}></button>
      </div>
      <div className="match-footer">
          <button className="home-button" onPointerDown={handleHome}></button>
        </div>
      </div>
    );
  };

  export default MatchChat;
