// MAMessageApp.js
import React, { useState, useEffect, useContext } from 'react';
import './MAMessageApp.css';
import { ArrowLeft, Send } from "lucide-react";
import { AppContext } from './MatchApp/AppContext.js';
import { MAContext } from './MessageContext.js';

function MAMessageApp({ setCurrentApp, handleHomeClick }) {
  const [isAnimating, setIsAnimating] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isTransitioning, setIsTransitioning] = useState(false);

  const { conversationData, setConversationData } = useContext(MAContext);
  const { progressFlag } = useContext(AppContext);

  // 進行度に応じた対象ユーザー。ここでは user1 のみ対象とする例。
  const progressMappingB = {
    2: { userId: "user1" },
    3: { userId: "user1" },
    4: { userId: "user1" }
  };

  // progressFlag 更新時、新規メッセージを visible にする。
  // 会話が開いていれば同時に isRead も true にする。
  useEffect(() => {
    if (progressFlag >= 2 && progressFlag <= 4 && progressMappingB[progressFlag]) {
      const { userId } = progressMappingB[progressFlag];
      setConversationData(prevData => {
        if (!prevData[userId]) return prevData;
        const conv = prevData[userId];
        if (!conv.messagesByFlag[progressFlag]) return prevData;
        const updatedGroup = conv.messagesByFlag[progressFlag].map(msg => ({
          ...msg,
          visible: true,
          // 会話が開いていれば自動で既読にする
          isRead: selectedUserId === userId ? true : msg.isRead
        }));
        return {
          ...prevData,
          [userId]: {
            ...conv,
            messagesByFlag: {
              ...conv.messagesByFlag,
              [progressFlag]: updatedGroup
            },
            lastMessage: updatedGroup[updatedGroup.length - 1].text
          }
        };
      });
    }
  }, [progressFlag, setConversationData, selectedUserId]);

  // 未読判定は、visible なメッセージのみ対象にする
  const hasUnread = (conv) => {
    const groups = Object.values(conv.messagesByFlag);
    return groups.some(group => group.some(m => m.visible && !m.isSent && !m.isRead));
  };

  // 会話選択時は、現在表示中のグループ（visible なもの）のみ既読にする
  const handleUserSelect = (userId) => {
    setConversationData(prevData => {
      const conv = prevData[userId];
      const updatedMessagesByFlag = {};
      Object.keys(conv.messagesByFlag).forEach(flag => {
        updatedMessagesByFlag[flag] = conv.messagesByFlag[flag].map(m => {
          // 既に visible なメッセージのみ既読にする
          return m.visible ? { ...m, isRead: true } : m;
        });
      });
      return {
        ...prevData,
        [userId]: {
          ...conv,
          messagesByFlag: updatedMessagesByFlag
        }
      };
    });
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedUserId(userId);
      setIsTransitioning(false);
    }, 300);
  };

  // 戻る処理
  const handleBack = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedUserId(null);
      setIsTransitioning(false);
    }, 300);
  };

  // ユーザー送信メッセージは常に表示グループ（flag 1）に追加する
  const handleSendMessage = () => {
    if (!newMessage.trim() || !selectedUserId) return;
    const messageToAdd = {
      id: Date.now(), // ユニークなIDに注意
      text: newMessage,
      isSent: true,
      isRead: true,
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      visible: true,
      flagwhen: 1
    };
    setConversationData(prevData => {
      const conv = prevData[selectedUserId];
      const updatedGroup = conv.messagesByFlag[1] ? [...conv.messagesByFlag[1], messageToAdd] : [messageToAdd];
      return {
        ...prevData,
        [selectedUserId]: {
          ...conv,
          messagesByFlag: {
            ...conv.messagesByFlag,
            1: updatedGroup
          },
          lastMessage: messageToAdd.text
        }
      };
    });
    setNewMessage("");
  };

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

  // 選択中の会話を取得
  const selectedConversation = selectedUserId ? conversationData[selectedUserId] : null;

  // visible なメッセージを結合して表示する
  const renderChatMessages = () => {
    if (!selectedConversation) return null;
    let visibleMessages = [];
    Object.values(selectedConversation.messagesByFlag).forEach(group => {
      visibleMessages = visibleMessages.concat(group.filter(msg => msg.visible));
    });
    visibleMessages.sort((a, b) => a.id - b.id);
    return visibleMessages.map(msg => (
      <div key={msg.id} className={`chat-message ${msg.isSent ? 'sent' : 'received'}`}>
        <div className="message-bubble">
          <p>{msg.text}</p>
          <p className="timestamp">{msg.timestamp}</p>
        </div>
      </div>
    ));
  };

  return (
    <div className={`ma-message-app-container ${isAnimating ? 'ma-message-app-homeback' : ''}`}>
      <div className="ma-message-app">
        { !selectedUserId ? (
          <div className={`user-selection ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
            <div className="header">
              <h1>メッセージ</h1>
            </div>
            <div className="user-list">
              {Object.values(conversationData).map(conv => (
                <div
                  key={conv.id}
                  className="user-item"
                  onClick={() => handleUserSelect(conv.id)}
                  style={hasUnread(conv) ? { backgroundColor: 'red' } : {}}
                >
                  <img src={conv.avatar} alt={conv.name} className="user-avatar" />
                  <div className="user-info">
                    <h2>{conv.name}</h2>
                    <p>{conv.lastMessage}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={`chat-screen ${isTransitioning ? 'slide-out' : 'slide-in'}`}>
            <div className="chat-header">
              <div className="chat-header-info">
                <button onClick={handleBack} className="MAback-button">
                  <ArrowLeft className="w-6 h-6 text-blue-500" />
                </button>
                <img src={selectedConversation.avatar} alt={selectedConversation.name} className="chat-avatar" />
                <span>{selectedConversation.name}</span>
              </div>
            </div>
            <div className="chat-messages">
              {renderChatMessages()}
            </div>
            <div className="chat-input-container">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message"
                className="chat-input"
              />
              <button onClick={handleSendMessage} className="send-button" disabled={!newMessage.trim()}>
                <Send className="w-6 h-6 text-blue-500" />
              </button>
            </div>
          </div>
        ) }
        <div className="MA-footer">
          <button className="home-button" onMouseDown={handleClick}></button>
        </div>
      </div>
    </div>
  );
}

export default MAMessageApp;
