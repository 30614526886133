//AppContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [callState, setCallState] = useState(() => sessionStorage.getItem("callState") || "C-overlay");

  const [isVerified, setIsVerified] = useState(() => JSON.parse(sessionStorage.getItem("isVerified") || "null"));
  
  const [fromMatchChat, setFromMatchChat] = useState(false);

  const taskMapping = {
    callOverlay: '???',
    callEnded: '通話終了、次のタスクに移行する。',
    isVerifiedFalse: 'マッチングアプリのやり取りを覗き見る',
    isVerifiedTrue: 'が分かり次第、～に報告する。'
  };

  let progressFlag;
  if (callState === "C-overlay") {
    progressFlag = 1;
  } else if (callState === "ended") {
    if (isVerified === true) {
      progressFlag = 4;
    } else if (isVerified === false) {
      progressFlag = 3;
    } else {
      progressFlag = 2;
    }
  } else {
    progressFlag = 1;
  }

  const progressMapping = {
    1: taskMapping.callOverlay,
    2: taskMapping.callEnded,
    3: taskMapping.isVerifiedFalse,
    4: taskMapping.isVerifiedTrue,
  };

  const taskText = progressMapping[progressFlag];

  // 進行度に応じた新規メッセージ数の対応表
  const newMessageCountMapping = {
    2: 1,
    3: 2,
    4: 3
  };

  const [newMessageCount, setNewMessageCount] = useState(0);

  useEffect(() => {
    console.log("AppContext: progressFlag changed to", progressFlag);
    if (progressFlag >= 2 && progressFlag <= 4) {
      const count = newMessageCountMapping[progressFlag] || 0;
      setNewMessageCount(count);
      console.log("AppContext: setting newMessageCount to", count);
    } else {
      setNewMessageCount(0);
      console.log("AppContext: setting newMessageCount to 0");
    }
  }, [progressFlag]);

  useEffect(() => {
    sessionStorage.setItem('callState', callState);
    sessionStorage.setItem('isVerified', JSON.stringify(isVerified));
  }, [callState, isVerified]);
  
  useEffect(() => {
    sessionStorage.setItem('progressFlag', JSON.stringify(progressFlag));
  }, [progressFlag]);

  return (
    <AppContext.Provider value={{ 
      fromMatchChat, setFromMatchChat, 
      isVerified, setIsVerified, 
      callState, setCallState,
      progressFlag, taskText,
      newMessageCount, setNewMessageCount
    }}>
      {children}
    </AppContext.Provider>
  );
};
