//MessageContext.js

import React, { createContext, useState, useEffect } from 'react';

export const MessageContext = createContext();
export const ResponseContext = createContext();

//Underでのメッセージやり取り
export const UnderMessageProvider = ({ children }) => {
  const [messagesByUser, setMessagesByUser] = useState({
    user11: [
      { text: "こんにちは！マッチありがとう😊", user: true, date: "2024/01/10" },
      { text: "こちらこそ！こちらも嬉しいです！", user: false, bot: true, date: "2024/01/10" },
      { text: "今度どこかでご飯でもどう？", user: true, date: "2024/01/10" },
      { text: "いいですね！友達も誘って2:2でどうかな？", user: false, bot: true, date: "2024/01/11" },
      { text: "いいね！2:2って気楽で楽しいよね！じゃあ、友達に声かけとく～！", user: true, date: "2024/01/11" },
      { text: "了解です！日程とか決まったら教えてね✨", user: false, bot: true, date: "2024/01/12" },
      { text: "友達一人つかまったんだけど、来週月曜の夜とか都合どう？", user: true, date: "2024/01/13" },
      { text: "OKです！場所とか決めとく？", user: false, bot: true, date: "2024/01/13" },
      { text: "澁谷駅近くでいいかな？適当に居酒屋とかで。", user: true, date: "2024/01/13" },
      { text: "いいね！じゃあ当日よろしくー！", user: false, bot: true, date: "2024/01/14" },
      { text: "おはよう！ごめん、急なんだけど友達が急用で来れなくなっちゃた…", user: true, date: "2024/01/15" },
      { text: "あら、そうなんだ！1人でも大丈夫だけど、気まずくない？", user: false, bot: true, date: "2024/01/15" },
      { text: "俺は全然大丈夫！もしよかったらそのまま2:1で会おう！", user: true, date: "2024/01/15" },
      { text: "OK！じゃあ、3人で会おう！楽しみにしてるね😊", user: false, bot: true, date: "2024/01/15" }
    ],
    user12: [
      { text: "美月です、よろしくお願いします！", user: false, bot: true ,date: "2024/01/13" },
      { text: "よろしく！", user: true ,date: "2024/01/13"},
      { text: "今日は天気がいいね。", user: false, bot: true ,date: "2024/01/13"}
    ]
  });

  const setMessagesForUser = (userId, newMessages) => {
    setMessagesByUser((prevMessagesByUser) => ({
      ...prevMessagesByUser,
      [userId]: newMessages,
    }));
  };

  return (
    <MessageContext.Provider value={{ messagesByUser, setMessagesForUser }}>
      {children}
    </MessageContext.Provider>
  );
};


//テキストコンソールへの返答
export const ResponseProvider = ({ children }) => {
  const [responseList] = useState([
    "こんにちは！今日はどう？",
    "何か質問はある？",
    "助けが必要なら教えてね。",
    "元気そうだね！",
    "他に話したいことがあればどうぞ。",
  ]);

  const [responseIndex, setResponseIndex] = useState(0);

  const getNextResponse = () => {
    const response = responseList[responseIndex];
    setResponseIndex((prevIndex) => (prevIndex + 1) % responseList.length);
    return response;
  };

  useEffect(() => {
    console.log("ResponseProvider initialized", getNextResponse); // ログを追加して確認
  }, []);

  return (
    <ResponseContext.Provider value={{ getNextResponse }}>
      {children}
    </ResponseContext.Provider>
  );
};


export const response4Camera = {
  "2023-11-07 14:30": `https://d15k62eom0hyt7.cloudfront.net/360video/360_003.mp4`,
  "2024-11-16 19:10": `${process.env.PUBLIC_URL}/images/初期360カメラ.jpg`,
  "2024-12-01 12:00": "/videos/video2.mp4",
};

export const MAContext = createContext();

const initialNeoconversations = {
  user1: {
    id: "user1",
    name: "非通知",
    avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150",
    // messagesByFlag のキー1は初期/ユーザー送信メッセージは常時表示、2～は進行度対応メッセージ（初期は非表示）
    messagesByFlag: {
      1: [
      ],
      2: [
        { id: 1, text: "着信がありました。進行度２。", isSent: false, isRead: false, timestamp: "09:40", visible: false, flagwhen: 2 }
        
      ],
      3: [
        { id: 102, text: "進行度３です。", isSent: false, isRead: false, timestamp: "09:45", visible: false, flagwhen: 3 },
        { id: 103, text: "気をつけてね", isSent: false, isRead: false, timestamp: "09:46", visible: false, flagwhen: 3 }
      ],
      4: [
        { id: 104, text: "進行度４です。", isSent: false, isRead: false, timestamp: "09:50", visible: false, flagwhen: 4 }
      ]
    },
    lastMessage: "See you tomorrow! 👋"
  },
  user2: {
    id: "user2",
    name: "John Smith",
    avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150",
    messagesByFlag: {
      1: [
        { id: 1, text: "Hey, could you help me with something?", isSent: false, isRead: true, timestamp: "10:15", visible: true, flagwhen: 1 },
        { id: 2, text: "Of course! What do you need?", isSent: true, isRead: true, timestamp: "10:16", visible: true, flagwhen: 1 },
        { id: 3, text: "Thanks for your help!", isSent: false, isRead: true, timestamp: "10:17", visible: true, flagwhen: 1 }
      ]
    },
    lastMessage: "Thanks for your help!"
  },
  user3: {
    id: "user3",
    name: "Emma Wilson",
    avatar: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=150",
    messagesByFlag: {
      1: [
        { id: 1, text: "Hi, when is the meeting today?", isSent: false, isRead: true, timestamp: "11:00", visible: true, flagwhen: 1 },
        { id: 2, text: "The meeting is at 3 PM", isSent: true, isRead: true, timestamp: "11:01", visible: true, flagwhen: 1 }
      ]
    },
    lastMessage: "The meeting is at 3 PM"
  }
};

export const MessageProvider = ({ children }) => {
  const [conversationData, setConversationData] = useState(() => ({ ...initialNeoconversations }));
  return (
    <MAContext.Provider value={{ conversationData, setConversationData }}>
      {children}
    </MAContext.Provider>
  );
};