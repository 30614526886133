//App.js
import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import Disclaimer from './Disclaimer';
import FirstCalling from "./first-calling";
import Match from './Match';
import MatchID from './MatchApp/Match-ID';
import MatchChat from './MatchApp/Match-chat';
import MatchSwipe from './MatchApp/Match-swipe';
import Camera from './Camera.js';
import Calendar from './calendar.js';
import BankApp from './BankApp.js';
import PhotoApp from './PhotoApp.js';
import MapApp from './MapApp';
import YouTubeStudio from './YouTubeStudio.js';
import ObaAssistant from './ObaAssistant';
import { UnderMessageProvider } from './MessageContext.js'; // UnderMessageProviderをインポート
import { MessageProvider } from './MessageContext';
import { AppContext } from './MatchApp/AppContext.js';
import { AppProvider } from './MatchApp/AppContext.js';
import FullScreenImage from './FullScreenImage';
import MAMessageApp from './MAMessageApp.js';
import { LoaderCircle, Battery, WifiHigh, SignalHigh } from 'lucide-react';

// 画像を参照
// const backgroundImage = `${process.env.PUBLIC_URL}/images/background.webp`;
const photosIcon = `${process.env.PUBLIC_URL}/images/photos-icon.png`;
const cameraIcon = `${process.env.PUBLIC_URL}/images/camera-icon.png`;
const underIcon = `${process.env.PUBLIC_URL}/images/swipe.png`;
const bankIcon = `${process.env.PUBLIC_URL}/images/bank-icon.png`;
const YoutubeIcon = `${process.env.PUBLIC_URL}/images/Youtube-icon.png`;
const messageIcon = `${process.env.PUBLIC_URL}/images/MA-icon.png`;

const applications = [
  { name: '写真', id: 'photo', icon: photosIcon },
  // { name: 'YS', id: 'youtube-ST', icon: YoutubeIcon },  
  // { name: 'カメラ', id: 'camera', icon: cameraIcon },
  { name: 'under', id: 'under', icon: underIcon },
  { name: 'BANK', id: 'bank', icon: bankIcon },
  { name: 'Messages', id: 'messages', icon: messageIcon },
];

// FFTのサイズ（波形の棒の数に影響。増やすと波形が詳細に、減らすと粗くなる。）
const FFT_SIZE = 2 ** 13;

// カレンダーウィジェットコンポーネント
const CalendarWidget = ({ AppClick }) => {
  const today = new Date();
  const day = today.getDate();
  const weekday = today.toLocaleDateString('ja-JP', { weekday: 'short' });

  return (
    <AppContext.Consumer>
      {({ taskText }) => (
        <div className="calendar-widget" onClick={() => AppClick({ id: 'calendar' })}>
          <div className='calendar-container'>
            <div className="calendar-widget-header">
              <span className="calendar-widget-weekday">{weekday}</span>
              <span className="calendar-widget-date">{day}</span>
            </div>
            <div className="calendar-widget-tasks">
              <div className="calendar-widget-task">
                {taskText}
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};

// MAPウィジェットコンポーネント
const GoogleMapWidget = ({ AppClick, downloading }) => {
  return (
    <div className="google-map-widget" onClick={() => AppClick({ id: 'google-map' })}>
      <img
        src={`${process.env.PUBLIC_URL}/images/mapwidget.png`} // 画像パスを指定
        alt="Google Map Icon"
        className="google-map-widget-image"
      />
      {downloading && (
        <div className="downloading-overlay" style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <LoaderCircle className="widgetspinning" />
        </div>
      )}
    </div>
  );
};


function App({ signOut, user }) {
  const [showDisclaimer, setShowDisclaimer] = useState(() => {
    return sessionStorage.getItem('disclaimerAccepted') !== 'true';
  });
  const [time, setTime] = useState(new Date());
  const [currentApp, setCurrentApp] = useState("first-call");
  const { newMessageCount, setNewMessageCount, progressFlag } = useContext(AppContext);
  const [downloading, setDownloading] = useState({});

  // Disclaimer の同意が完了したら、sessionStorage に記録してオーバーレイを削除
  const handleAcceptDisclaimer = () => {
    sessionStorage.setItem('disclaimerAccepted', 'true');
    setShowDisclaimer(false);
  };

  const handleEndCall = () => {
    setCurrentApp(null); // 電話画面を消す
  };

  const [isAnimating, setIsAnimating] = useState(false); // Add useState for isAnimating

  const [battery, setBattery] = useState(100); // バッテリー残量のモックデータ

  // handlePhotoSelectedを定義
  const handlePhotoSelected = (photo) => {
    console.log('Photo selected:', photo); // 選択された写真を確認
  };

  // 時刻の自動更新（1秒ごと）
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  },);

  const AppClick = (app) => {
    if (app.id === 'bank' || app.id === 'google-map') {
      if (progressFlag < 3) {
        if (downloading[app.id]) return;
        setDownloading(prev => ({ ...prev, [app.id]: true }));
        return;
      }
    }
    setIsAnimating(true);
    setTimeout(() => {
      if (app.id === 'under') {
        setCurrentApp('match');
      } else if (app.id === 'camera') {
        setCurrentApp('camera');
      } else if (app.id === 'calendar') {
        setCurrentApp('calendar');
      } else if (app.id === 'youtube-ST') {
        setCurrentApp('youtube-ST');
      } else if (app.id === 'bank' && progressFlag !== 3) {
        setCurrentApp('bank');
      } else if (app.id === 'google-map' && progressFlag !== 3) {
        setCurrentApp('google-map');
      } else if (app.id === 'messages') {
        setCurrentApp('messages');
      } else {
        setCurrentApp(app.id);
      }
      setIsAnimating(false);
    }, 300);
  };

  const handleHomeClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

  // メッセージアプリが開かれたらバッジをリセットする
  useEffect(() => {
    if (currentApp === 'messages') {
      console.log("App.js: Message app opened, resetting badge count");
      setNewMessageCount(0);
    }
  }, [currentApp, setNewMessageCount]);


  useEffect(() => {
    if (progressFlag >= 3) {
      setDownloading(prev => {
        const newState = { ...prev };
        delete newState.bank;
        delete newState['google-map'];
        return newState;
      });
    }
  }, [progressFlag]);

  return (
    <MessageProvider>
      <UnderMessageProvider>
        <div className="App">
          {/* Disclaimer オーバーレイは、showDisclaimer が true のときに App の上に表示される */}
          {showDisclaimer && <Disclaimer onAccept={handleAcceptDisclaimer} />}
          {/* <ObaAssistant /> */}
          <header className="App-header">
            {/* <div className="login-info">
            {user ? (
              <>
                <h3>{user.username}がログイン中</h3>
                <button onClick={signOut}>ログアウト</button>
              </>
            ) : (
              <h3>権限がありません</h3>
            )}
          </div> */}
            <div className={`phone-master ${isAnimating ? 'slide-up' : ''}`}>
              <div className="phone-mainframe"></div>
              <div className="phone">
                <div className="status-bar">
                  <span className="time">
                    {time.getHours().toString().padStart(2, '0')}:{time.getMinutes().toString().padStart(2, '0')}
                  </span>
                  <div className="right-icons">
                    <span className="signal-icon"><SignalHigh size={20} /></span>
                    <span className="wifi-icon"><WifiHigh size={24} /></span>
                    <span className="battery-icon">
                      <Battery size={30} fill="#111" />
                      <span style={{
                        position: 'absolute',
                        top: '45%',
                        left: '41%',
                        transform: 'translate(-50%, -50%)',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '9px'
                      }}>
                        {battery}
                      </span>
                    </span>
                  </div>
                </div>

                {/* 最初の電話かかってくる */}
                {currentApp === "first-call" && <FirstCalling onEndCall={handleEndCall} />}

                {currentApp !== 'calendar' && <CalendarWidget AppClick={AppClick} />}
                {currentApp === 'calendar' && <Calendar setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}

                <div className="applications">
                  {applications.map(app => (
                    <div key={app.id} className="app-container" onClick={() => AppClick(app)}>
                      <button className={`app-icon ${app.id}`}>
                        <img src={app.icon} alt={app.name} />
                        {app.id === 'messages' && newMessageCount > 0 && (
                          <span className="unread-badge">{newMessageCount}</span>
                        )}
                        {downloading[app.id] && (
                          <div className="downloading-overlay" style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0,0,0,0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <LoaderCircle />
                          </div>
                        )}
                      </button>
                      <span className="app-name">{app.name}</span>
                    </div>
                  ))}
                </div>
                {currentApp === 'match' && <Match setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
                {currentApp === 'match-id' && <MatchID setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
                {currentApp === 'match-swipe' && <MatchSwipe setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
                {currentApp === 'match-chat' && <MatchChat setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
                {currentApp === 'camera' && (<Camera setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} /> )}
                {currentApp === 'bank' && <BankApp setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
                {currentApp === 'youtube-ST' && <YouTubeStudio setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
                {currentApp === 'photo' && (
                  <PhotoApp
                    setCurrentApp={setCurrentApp}
                    handleHomeClick={handleHomeClick}
                    handlePhotoSelected={handlePhotoSelected} // handlePhotoSelectedをPhotoAppに渡す
                  />
                )}

                {currentApp !== 'google-map' && <GoogleMapWidget AppClick={AppClick} downloading={downloading['google-map']} />}
                {currentApp === 'google-map' && <MapApp handleHomeClick={handleHomeClick} setCurrentApp={setCurrentApp} />}

                {currentApp === 'messages' && (
                  <MAMessageApp
                    setCurrentApp={setCurrentApp}
                    handleHomeClick={handleHomeClick}
                  // setUnreadCount={setUnreadCount}
                  />
                )}

                <div className="phone-dock">
                  <button
                    className="dock-icon"
                    onClick={() => AppClick({ id: 'camera' })}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/camera-icon.png`}
                      alt="カメラ"
                      className="dock-icon-img"
                    />
                  </button>
                </div>
              </div>
            </div>
          </header>
        </div>
      </UnderMessageProvider>
    </MessageProvider>
  );
}

export default App;