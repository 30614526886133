//camera.js
import React, { useState, useEffect, useRef } from 'react';
import './Camera.css';
import './components/custom-look-controls';
import FullScreenImage, { BlackBlink } from './FullScreenImage';

const Camera = ({ setCurrentApp, handleHomeClick, videoSource }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [selectedMode, setSelectedMode] = useState('写真');
  const initialViewMode = (() => {
    const savedVideoSource = sessionStorage.getItem('specialTIME');
    return (savedVideoSource && savedVideoSource.endsWith('.mp4')) ? 'video' : 'image';
  })();
  const [viewMode, setViewMode] = useState(initialViewMode);
  
  
  const [showDLPopup, setShowDLPopup] = useState(false); // ポップアップ表示用の状態
  const [isPopupFinished, setIsPopupFinished] = useState(false); // アニメーションが終了したかどうかを管理
  useEffect(() => {
    const storedPopupFinished = localStorage.getItem('isPopupFinished');
    if (storedPopupFinished === 'true') {
      setIsPopupFinished(true);
    }
  }, []);
  
  const [isCursorOnPlane, setIsCursorOnPlane] = useState(false); // カーソルが板の上にあるかの状態を管理
  const [offset, setOffset] = useState(0);
  const modesRef = useRef(null);
  const flashRef = useRef(null);
  const panoramaRef = useRef(null);
  const videoRef = useRef(null);
  const cameraRef = useRef(null);  // カメラ参照用
  
  const [showFullScreen, setShowFullScreen] = useState(false);
  // BlackBlink を表示するかどうか、及びどのタイプのアニメーションを実行するかを管理
  const [showBlink, setShowBlink] = useState(false);
  const [blinkType, setBlinkType] = useState(''); // 'closing' or 'opening'

  // dollPlaneA クリック時の処理：FullScreenImage を表示する
  const handleDollPlaneAClick = () => {
    // まず、camera.js 側で closing アニメーションを実行
    setBlinkType('closing');
    setShowBlink(true);
    setTimeout(() => {
      // closing アニメーション終了後に FullScreenImage を表示
      setShowFullScreen(true);
      // ここでは setShowBlink(false) を呼ばず、FullScreenImage の opening アニメーション開始直前に
      // 親からのコールバックで setShowBlink(false) を呼ぶようにする
    }, 1000);
  };

  // FullScreenImage 側から呼ばれる onClose コールバック
  const handleFullScreenClose = () => {
    // FullScreenImage の画面を閉じる際、まず BlackBlink で closing アニメーションを実行
    setShowFullScreen(false);
    setBlinkType('opening');
    setShowBlink(true);
    const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/move.wav`);
    audio.play();
  };  

  useEffect(() => {
    if (panoramaRef.current) {
      const scene = document.createElement('a-scene');
      scene.setAttribute('embedded', 'true');
      // scene.setAttribute('vr-mode-ui', 'enabled: false'); // VRボタンを無効にする
      scene.setAttribute('device-orientation-permission-ui', 'enabled: false'); // ジャイロ許可UIを無効にする
      scene.style.width = '100%';
      scene.style.height = '100%';


      const assets = document.createElement('a-assets');
      const video = document.createElement('video');
      const image = document.createElement('a-sky');

      video.setAttribute('id', 'panoramaVideo');

      // セッションストレージから保存されたURLを取得
      const savedVideoSource = sessionStorage.getItem('specialTIME');
      // const defaultImageSource = `${process.env.PUBLIC_URL}/images/初期360カメラ.webp`;
      const defaultImageSource = `https://d15k62eom0hyt7.cloudfront.net/360video/test.webp`;
      // const defaultVideoSource = `https://d15k62eom0hyt7.cloudfront.net/360video/360_001.mp4`;
      const defaultVideoSource = `https://d15k62eom0hyt7.cloudfront.net/360video/360_1920_960.mp4`;
      // const defaultVideoSource = `${process.env.PUBLIC_URL}/video/VID_20240809_144546_00_017.mp4`;


      // 動画モード用設定
      if (viewMode === 'video') {
        video.setAttribute('src', savedVideoSource || defaultVideoSource);
        video.setAttribute('loop', 'true');
        video.setAttribute('crossorigin', 'anonymous');
        video.setAttribute('autoplay', 'true');
        assets.appendChild(video);

        const videosphere = document.createElement('a-videosphere');
        videosphere.setAttribute('src', '#panoramaVideo');
        videosphere.setAttribute('rotation', '0 -90 0');
        scene.appendChild(videosphere);
      }

      // 画像モード用設定
      if (viewMode === 'image') {
        image.setAttribute('src', savedVideoSource || defaultImageSource);
        image.setAttribute('rotation', '0 -90 0');
        scene.appendChild(image);
      }


      // カメラ設定を追加
      const camera = document.createElement('a-entity');
      camera.setAttribute('id', 'cam');
      camera.setAttribute('camera', 'zoom: 1; active: true');

      // タッチ対応かどうかをチェックして、適用するコントロールを分ける
      const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
      if (isTouchDevice) {
        camera.setAttribute('touch-look-controls', '');
      } else {
        camera.setAttribute(
          'look-controls',
          'reverseMouseDrag: true; magicWindowTrackingEnabled: false; touchEnabled: true; pointerLockEnabled: false'
        );
      }


      //カーソルを追加
      const cursor = document.createElement('a-cursor');
      cursor.setAttribute('color', 'yellow');
      cursor.setAttribute('fuse', 'false'); 
      cursor.setAttribute('fuse-timeout', '500');
      cursor.setAttribute('opacity', '0');
      cursor.object3D.renderOrder = 2; // オーバーレイをカーソルの背面に配置
      camera.appendChild(cursor);
      scene.appendChild(camera);

      // カスタムカーソル画像を追加
      const customCursor = document.createElement('a-image');
      customCursor.setAttribute('src', `${process.env.PUBLIC_URL}/images/custom-cursor.png`);
      customCursor.setAttribute('position', '0 0 -0.4'); 
      customCursor.setAttribute('width', '0.1');
      customCursor.setAttribute('height', '0.1');
      customCursor.setAttribute('visible', 'false');
      customCursor.object3D.renderOrder = 2; 
      camera.appendChild(customCursor); // カメラにカスタムカーソルを追加

      
      // セッションストレージのURLを確認して板を追加するか判定
      if (!savedVideoSource || savedVideoSource === 'https://d15k62eom0hyt7.cloudfront.net/360video/360_001_3.mp4') {
        // 板を追加
        const plane = document.createElement('a-plane');
        const planePosition = '1 -3 1'; // 位置
        const planeRotation = '-90 160 0'; // 回転
        const planeWidth = '2.4'; // 幅
        const planeHeight = '1.5'; // 高さ

        plane.setAttribute('src', `${process.env.PUBLIC_URL}/images/DriversLicense.png`);
        plane.setAttribute('position', planePosition);
        plane.setAttribute('rotation', planeRotation);
        plane.setAttribute('width', planeWidth);
        plane.setAttribute('height', planeHeight);
        plane.setAttribute('material', 'side: double');
        plane.object3D.renderOrder = 0;
        scene.appendChild(plane);

        // 赤いオーバーレイを追加
        const redOverlay = document.createElement('a-plane');

        // 板と同じ属性をオーバーレイに設定
        redOverlay.setAttribute('position', planePosition); // 位置
        redOverlay.setAttribute('rotation', planeRotation); // 回転
        redOverlay.setAttribute('width', planeWidth); // 幅
        redOverlay.setAttribute('height', planeHeight); // 高さ
        redOverlay.setAttribute('material', 'color: green; opacity: 0'); // 初期状態では透明
        redOverlay.setAttribute('side', 'double'); // 両面表示
        redOverlay.object3D.renderOrder = 1; // オーバーレイを前面に配置
        scene.appendChild(redOverlay);

        // 板にカーソルイベントを追加してオーバーレイを表示/非表示
        plane.addEventListener('mouseenter', () => {
          redOverlay.setAttribute('material', 'opacity', 0.5); // カーソルが当たったときにオーバーレイを表示
          customCursor.setAttribute('visible', 'true');// カスタムカーソルを表示
          setIsCursorOnPlane(true); // カーソルが板の上にあることを示す
        });

        plane.addEventListener('mouseleave', () => {
          redOverlay.setAttribute('material', 'opacity', 0); // カーソルが外れたときにオーバーレイを非表示
          customCursor.setAttribute('visible', 'false');
          setIsCursorOnPlane(false); // カーソルが板の上から外れたことを示す
        });
      }
      
      // PNGを表示する板を追加
      const dollPlaneA = document.createElement('a-plane');
      dollPlaneA.setAttribute('src', `${process.env.PUBLIC_URL}/images/swipe.png`); // 画像のパスを指定
      dollPlaneA.setAttribute('position', '-3 1.8 -3'); // 位置を適宜調整
      dollPlaneA.setAttribute('rotation', '20 20 0'); // 必要に応じて回転を調整
      dollPlaneA.setAttribute('width', '2'); // 板の幅
      dollPlaneA.setAttribute('height', '1'); // 板の高さ
      dollPlaneA.setAttribute('material', 'side: double; transparent: true; alphaTest: 0.1;'); // アルファ（透明）を適用

      dollPlaneA.setAttribute('class', 'clickable'); // CSS適用
      dollPlaneA.style.pointerEvents = 'auto'; // クリックを拾う
      
      // クリック処理
      dollPlaneA.addEventListener('click', () => {
        console.log('dollPlaneA clicked!');
        handleDollPlaneAClick();
      });
      
      // マウスオーバーで色変更
      dollPlaneA.addEventListener('mouseenter', () => {
        dollPlaneA.setAttribute('material', 'color', 'red'); // 赤色に変更
      });
      
      // マウスが離れたら元の色に戻す
      dollPlaneA.addEventListener('mouseleave', () => {
        dollPlaneA.setAttribute('material', 'color', 'white'); // 元の色（白）に戻す
      });
        

      scene.appendChild(dollPlaneA);

      scene.appendChild(assets);
      panoramaRef.current.appendChild(scene);
      cameraRef.current = camera;  // カメラ要素の参照を保存
      videoRef.current = video;    // ビデオ要素の参照を保存

      return () => {
        if (panoramaRef.current) {
          // シーンを完全に削除する
          while (panoramaRef.current.firstChild) {
            panoramaRef.current.removeChild(panoramaRef.current.firstChild);
          }
        }
      };
    }
  }, [viewMode, videoSource]);

  useEffect(() => {
    const index = 1; // 写真ボタンのインデックス
    const selectedButton = modesRef.current.children[index];
    const modeWidth = selectedButton.offsetWidth;
    const containerWidth = modesRef.current.offsetWidth;
    const containerCenter = containerWidth / 2;
    const buttonCenter = selectedButton.offsetLeft + (modeWidth / 2);
    const newOffset = containerCenter - buttonCenter;
    setOffset(newOffset);
  }, []);

  const handleClick = () => {
    setIsAnimating(true); // アニメーションを開始
    // アニメーションとホーム画面への遷移
    setTimeout(() => {
      handleHomeClick(); // App.jsにホームボタンの処理を実行
      setCurrentApp(null); // カメラアプリから戻る
      setIsAnimating(false); // アニメーションを終了
    }, 300);
  };
  
  

  const handleModeClick = (mode, index) => {
    setSelectedMode(mode);
    const selectedButton = modesRef.current.children[index];
    const modeWidth = selectedButton.offsetWidth;
    const containerWidth = modesRef.current.offsetWidth;
    const containerCenter = containerWidth / 2;
    const buttonCenter = selectedButton.offsetLeft + (modeWidth / 2);
    const newOffset = containerCenter - buttonCenter;
    setOffset(newOffset);
  };

  const handleShutterClick = () => {
    const audio = new Audio(`https://d15k62eom0hyt7.cloudfront.net/360video/shutter-sound.mp3`);
    audio.play();

    if (flashRef.current) {
      flashRef.current.classList.add('flash');
      setTimeout(() => {
        flashRef.current.classList.remove('flash');
      }, 500);
    }


    // カーソルが板の上にある時にシャッターボタンを押した場合のみポップアップを表示
    if (isCursorOnPlane) {
      setShowDLPopup(true);
      setTimeout(() => {
        setShowDLPopup(false); // 3秒後にポップアップを非表示
        setIsPopupFinished(true); // アニメーション終了後のフラグを立てる
        localStorage.setItem('isPopupFinished', 'true'); // 状態をローカルストレージに保存

        // 写真アプリの最後にDriversLicense.pngを追加
        const storedPhotos = JSON.parse(localStorage.getItem('photos')) || [];
        const newPhoto = {
          id: 'drivers-license',
          previewURL: `${process.env.PUBLIC_URL}/images/DriversLicense.png`,
          largeImageURL: `${process.env.PUBLIC_URL}/images/DriversLicense.png`,
          tags: 'Drivers License'
        };
        storedPhotos.push(newPhoto);
        localStorage.setItem('photos', JSON.stringify(storedPhotos)); // 画像リストをローカルストレージに保存
      }, 3000);
  } 

  };

  // フルスクリーン状態を判定する関数
  const isFullScreen = () => {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
  };

  // スクロールやピンチ操作でのズーム
  useEffect(() => {
    const handleZoom = (event) => {
      if (isFullScreen()) {  // フルスクリーン状態でのみズームを実行
        const delta = event.deltaY ? -event.deltaY : event.scale - 1; // スクロールまたはピンチによるズーム
        const zoomFactor = delta / 1000;
        if (cameraRef.current) {
          let currentZoom = cameraRef.current.getAttribute('camera').zoom;
          let newZoom = Math.max(0.5, Math.min(2, currentZoom + zoomFactor)); // ズーム範囲を制限
          cameraRef.current.setAttribute('camera', 'zoom', newZoom);
        }
      }
    };

    window.addEventListener('wheel', handleZoom);
    window.addEventListener('gesturechange', handleZoom); // モバイルのピンチ操作

    return () => {
      window.removeEventListener('wheel', handleZoom);
      window.removeEventListener('gesturechange', handleZoom);
    };
  }, []);

  return (
    <div className={`cameraApp-container ${isAnimating ? 'game-home-back' : ''}`}>
      <div className="camera-header"></div>
      <div className="camera-body">
        <div ref={flashRef} className="camera-flash"></div>
        <div id="panorama" ref={panoramaRef} style={{ width: '100%', height: '100%' }}></div>
        <div className="camera-ui">
          <div className="camera-top-bar">
            {/* <button className="camera-flash-icon">⚡</button> */}
            {/* <button className="camera-settings-icon">⚙️</button> */}
          </div>
          <div className="camera-bottom-bar" ref={modesRef} style={{ transform: `translateX(${offset}px)` }}>
            <button
              className={`camera-mode ${selectedMode === 'ポートレート' ? 'selected' : ''}`}
              onClick={() => handleModeClick('ポートレート', 0)}
            >
              ポートレート
            </button>
            <button
              className={`camera-mode ${selectedMode === '写真' ? 'selected' : ''}`}
              onClick={() => handleModeClick('写真', 1)}
            >
              写真
            </button>
            <button
              className={`camera-mode ${selectedMode === 'パノラマ' ? 'selected' : ''}`}
              onClick={() => handleModeClick('パノラマ', 2)}
            >
              パノラマ
            </button>
          </div>
          <button className="camera-shutter-button" onClick={handleShutterClick}></button>
        </div>
      </div>
      {/* ポップアップの表示 */}
      {showDLPopup && (
        <div className="popup-DL">
          <img src={`${process.env.PUBLIC_URL}/images/DriversLicense.png`} alt="Drivers License" className="popup-DLimage" />
        </div>
      )}
       {/* アニメーション終了後にDriversLicense.pngを固定表示 */}
       {isPopupFinished && (
         <div className="final-image">
           <img src={`${process.env.PUBLIC_URL}/images/DriversLicense.png`} alt="Drivers License" className="final-image-style" />
         </div>
      )}
      <div className="camera-footer">
        <button className="home-button" onMouseDown={handleClick}></button>
      </div>

      {/* camera.js 側の BlackBlink（遷移演出用） */}
      {showBlink && (
        <BlackBlink type={blinkType} onAnimationEnd={() => { /* 必要なら追加処理 */ }} />
      )}

      {/* FullScreenImage の表示。onOpeningAnimationStart により、FullScreenImage 側の opening アニメーション開始直前に呼ばれた時点でカメラ側の BlackBlink を非表示にする */}
      {showFullScreen && (
        <FullScreenImage
          onClose={handleFullScreenClose}
          startAnimation={true}
          onOpeningAnimationStart={() => setShowBlink(false)}
        />
      )}
    </div>
  );
};

export default Camera;