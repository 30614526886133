// Disclaimer.js
import React, { useState } from 'react';

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgb(0, 0, 0)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  zIndex: 9999, // 最前面に表示
};

const contentStyle = {
  background: 'rgb(24, 24, 24)',
  padding: '2rem',
  maxWidth: '700px',
  margin: '1rem',
  borderRadius: '8px',
  textAlign: 'center',
};

const buttonStyle = {
  background: 'linear-gradient(45deg, rgb(86, 100, 105), rgb(53, 67, 85))',
  border: 'none',
  color: 'white',
  padding: '0.75rem 1.5rem',
  fontSize: '1.2rem',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  marginTop: '1rem',
};

const buttonHoverStyle = {
  transform: 'scale(1.05)',
  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.4)',
};

const Disclaimer = ({ onAccept }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const currentButtonStyle = isHovered
    ? { ...buttonStyle, ...buttonHoverStyle }
    : buttonStyle;

  // ボタンがクリックされたとき、フェードアウトを開始
  const handleButtonClick = () => {
    setIsFadingOut(true);
    // フェードアウトのアニメーション時間（0.5秒）と合わせる
    setTimeout(() => {
      onAccept();
    }, 2000);
  };

  return (
    <div
      style={{
        ...overlayStyle,
        opacity: isFadingOut ? 0 : 1,
        transition: 'opacity 2s ease-out',
      }}
    >
      <div style={contentStyle}>
        <p>
          本作品は、山崎家にまつわる出来事を基にした再現ドキュメンタリーです。<br />
          なお、本作は、当該邸宅に現在も留まり続ける“ある存在”によるもの証言のもと再構成されたものであり、その真偽については保証いたしかねます。何卒ご了承ください。
        </p>
        <button
          style={currentButtonStyle}
          onClick={handleButtonClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          了承する
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;
