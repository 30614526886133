/* global AFRAME, THREE */

AFRAME.registerComponent('touch-look-controls', {
    schema: {
      touchEnabled: { default: true }
    },
    init: function () {
      // カメラの回転用オブジェクトを作成
      this.pitchObject = new THREE.Object3D();
      this.yawObject = new THREE.Object3D();
      this.yawObject.add(this.pitchObject);
      this.touchStart = null;
  
      // A-Frame の canvas はシーンのロード後に生成されるので、
      // 存在しない場合は renderstart イベントでリスナーを登録
      if (this.el.sceneEl.canvas) {
        this.attachEventListeners();
      } else {
        this.el.sceneEl.addEventListener('renderstart', this.attachEventListeners.bind(this));
      }
    },
    attachEventListeners: function () {
      const canvas = this.el.sceneEl.canvas;
      canvas.addEventListener('touchstart', this.onTouchStart.bind(this), false);
      canvas.addEventListener('touchmove', this.onTouchMove.bind(this), false);
    },
    onTouchStart: function (evt) {
      if (!this.data.touchEnabled) { return; }
      this.touchStart = {
        x: evt.touches[0].pageX,
        y: evt.touches[0].pageY
      };
    },
    onTouchMove: function (evt) {
      if (!this.touchStart || !this.data.touchEnabled) { return; }
      const canvas = this.el.sceneEl.canvas;
      // タッチの移動量（ピクセル単位）を取得
      const deltaX = evt.touches[0].pageY - this.touchStart.y; // 上下の移動
      const deltaY = evt.touches[0].pageX - this.touchStart.x; // 左右の移動
  
      // 画面サイズに応じた回転量を計算（係数は調整可能）
      this.pitchObject.rotation.x += 0.6 * Math.PI * deltaX / canvas.clientHeight;
      this.yawObject.rotation.y   += 1.0 * Math.PI * deltaY / canvas.clientWidth;
      
      // ピッチ（上下）の回転角を -90° ～ 90° に制限
      this.pitchObject.rotation.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, this.pitchObject.rotation.x));
  
      // 次回用にタッチ開始位置を更新
      this.touchStart = {
        x: evt.touches[0].pageX,
        y: evt.touches[0].pageY
      };
    },
    tick: function () {
      // 毎フレーム、計算された回転をカメラ（このエンティティ）の object3D に反映
      this.el.object3D.rotation.x = this.pitchObject.rotation.x;
      this.el.object3D.rotation.y = this.yawObject.rotation.y;
    }
  });
  