import React, { useState } from 'react';
import './YouTubeStudio.css';

const YouTubeStudio = ({ setCurrentApp, handleHomeClick }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [selectedTab, setSelectedTab] = useState("ダッシュボード");

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

  const videos = [
    { id: 1, title: 'Cygamesのモーションキ…', duration: '46:23', views: 0, likes: 0 },
    { id: 2, title: '任天堂 質疑', duration: '31:37', views: 0, likes: 0 },
    { id: 3, title: '任天堂 質疑', duration: '31:37', views: 0, likes: 0 },
  ];

  const shorts = [
    { id: 1, title: '2024年12月22日', thumbnail: '/short1.jpg' },
    { id: 2, title: '半実在ARG漫画…', thumbnail: '/short2.jpg' },
    { id: 3, title: 'ARG地図Google…', thumbnail: '/short3.jpg' },
  ];

  const playlists = [
    { id: 1, title: 'HOLD ON TIGHT', thumbnail: '/playlist1.jpg' },
    { id: 2, title: 'ChatGPT MidJourney', thumbnail: '/playlist2.jpg' },
  ];

  return (
    <div className={`youtube-studio-header ${isAnimating ? 'YTHomeback' : ''}`}>
      <header className="header">
        <div className="flex items-center gap-2">
          <img
            src="images/youtube-studio.png"
            alt="YouTube Studio ロゴ"
            className="h-8"
          />
          <h1 className="font-roboto text-xl">Studio</h1>
        </div>
        <div className="flex items-center gap-4">
          <button className="button">
            <i className="fas fa-plus text-xl"></i>
          </button>
          <button className="button">
            <i className="fas fa-search text-xl"></i>
          </button>
          <button className="button">
            <i className="fas fa-bell text-xl"></i>
          </button>
        </div>
      </header>

      <nav className="nav">
        <button className="button">種類<i className="fas fa-chevron-down ml-1"></i></button>
        <button className="button">公開設定<i className="fas fa-chevron-down ml-1"></i></button>
        <button className="button">視聴回数<i className="fas fa-chevron-down ml-1"></i></button>
        <button className="button">制限の理由<i className="fas fa-chevron-down ml-1"></i></button>
      </nav>

      <main className="main">
        <div className="flex justify-between mb-4">
          <h2 className="font-roboto text-2xl">動画</h2>
          <button className="button bg-gray-100">すべて表示</button>
        </div>
        <div className="grid">
          {videos.map((video) => (
            <div className="video-card" key={video.id}>
              <div className="video-preview"></div>
              <h3 className="YT-video-title">{video.title}</h3>
              <div className="flex gap-4 text-gray-600">
                <span>
                  <i className="fas fa-lock"></i> {video.views}
                </span>
                <span>
                  <i className="fas fa-chart-simple"></i> {video.duration}
                </span>
                <span>
                  <i className="fas fa-thumbs-up"></i> {video.likes}
                </span>
              </div>
            </div>
          ))}
        </div>
      </main>
      
      <div className="studio-footer">
        <button className="home-button" onMouseDown={handleClick}></button>
      </div>
    </div>
  );
};

export default YouTubeStudio;