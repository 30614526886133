import React, { useEffect, useRef ,useState } from 'react';
import './MapApp.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const MapApp = ({ setCurrentApp, handleHomeClick }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };
  const mapRef = useRef(null); // マップインスタンスを保持する参照
  const sceneRef = useRef(null);
  
  //ドラッグ中フラグと開始位置、マーカー回転を管理するためのState & Ref
  const [isDragging, setIsDragging] = useState(false);
  const startPos = useRef({ x: 0, y: 0 });
  const [markers, setMarkers] = useState([]); // 複数マーカーを管理 
  // 状態管理に累積回転角度を追加
  const [totalRotation, setTotalRotation] = useState(0);
  
  const [isStreetView, setIsStreetView] = useState(false); // ストリートビューモードの状態
  const initialView = { lat: 35.681236, lng: 139.767125, zoom: 15 }; // 初期ビュー設定

  const [circleLayers, setCircleLayers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // 選択された画像
  const [isImageVisible, setIsImageVisible] = useState(false); // フィールドの表示状態

  const circlesData = [
    { lat: 35.681236, lng: 139.767124, radius: 50, image: `${process.env.PUBLIC_URL}/images/PHONEbackground.jpg`  }, // 青い円1
    { lat: 35.684236, lng: 139.770125, radius: 50, image: `${process.env.PUBLIC_URL}/images/初期360カメラ.jpg`  }, // 青い円2
  ];
  
  // モード切り替え
  const handleStreetViewToggle = () => {
    setIsStreetView((prev) => !prev); 
  };

  // マップ表示
  useEffect(() => {
    if (!mapRef.current) {
      // マップの初期化
      mapRef.current = L.map('map', {
        maxBounds: L.latLngBounds(
          [35.636236, 139.717125], // 南西 (東京駅から約-0.045度)
          [35.726236, 139.817125]  // 北東 (東京駅から約+0.045度)
        ),
        maxBoundsViscosity: 1.0, // 範囲外へのスクロールを防止
        minZoom: 14, // 最小ズームレベル
        maxZoom: 17, // 最大ズームレベル
        zoomControl: false // ズームボタンを非表示に設定
      }).setView([initialView.lat, initialView.lng], initialView.zoom);      

      // タイルレイヤーの追加
      L.tileLayer('https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg', {
        attribution: '出典：国土地理院 空中写真（平成28年撮影）',
        maxZoom: 19,
      }).addTo(mapRef.current);
      

    // サイズを再計算
    setTimeout(() => {
      mapRef.current.invalidateSize();
    }, 300);
      

    } else {
      // 既存のマップがある場合、ビューをリセット
      mapRef.current.setView([35.681236, 139.767125], 15);
    }
  }, []); // 初回マウント時のみ実行

   // シーンが既に初期化されているか確認
  useEffect(() => {
    if (isImageVisible && sceneRef.current && selectedImage) {
      const handleSceneLoaded = () => {
        const sky = sceneRef.current.querySelector('a-sky');
        if (sky) {
          // 強制的に再読み込みするために一旦空にする
          sky.setAttribute('src', '');
          // 次のフレームで新しい画像を設定
          requestAnimationFrame(() => {
            sky.setAttribute('src', selectedImage);
          });
        }
      };

      // シーンがすでに読み込まれているかチェック
      if (sceneRef.current.hasLoaded) {
        handleSceneLoaded();
      } else {
        sceneRef.current.addEventListener('loaded', handleSceneLoaded);
      }

      // クリーンアップ関数
      return () => {
        if (sceneRef.current) {
          sceneRef.current.removeEventListener('loaded', handleSceneLoaded);
        }
      };
    }
  }, [isImageVisible, selectedImage]); // selectedImageの変更を監視

  // 現在地に戻るbutton
  const handleReturnToInitialView = () => {
    if (mapRef.current) {
      mapRef.current.setView([initialView.lat, initialView.lng], initialView.zoom);
    }
  };

  useEffect(() => {
    const mapContainer = document.getElementById('map');
    if (mapContainer) {
      mapContainer.style.height = '100%'; // コンテナの高さを設定
    }
  }, []);


  // ドラッグを開始
  const handleMouseDown = (e) => {
    setIsDragging(true);
    startPos.current = { x: e.clientX, y: e.clientY };
  };

  // マウスの移動とマウスアップイベントをwindowに設定
  useEffect(() => {    
    const handleGlobalMouseMove = (e) => {
      if (isDragging) {
        const diffX = e.clientX - startPos.current.x;
        
        // diffX を使ってマーカーを回転させる
        const rotationDelta = diffX * 0.1; //係数で回転スピードを調整
        
        // 累積回転角度を更新（-360から360の範囲で正規化）
        const newTotalRotation = ((totalRotation + rotationDelta) + 360) % 360;
        setTotalRotation(newTotalRotation);
  
        // 実際のマーカーの DOM 要素を探し、マーカーの回転角度を更新  (markers[0] があれば)
        if (markers[0]) {
          const markerEl = markers[0]._icon?.querySelector('.marker-icon');
          if (markerEl) {
            markerEl.style.transform = `rotate(${newTotalRotation}deg)`;
          }
        }
  
        // ドラッグ中は startPos も更新しないと、差分が累積し続けて過剰回転してしまう
        // （「1フレームずつ回転」のイメージ）
        startPos.current = { x: e.clientX, y: e.clientY };
      }
    };
  
    const handleGlobalMouseUp = () => {
      setIsDragging(false);
    };
  
    window.addEventListener('mousemove', handleGlobalMouseMove);
    window.addEventListener('mouseup', handleGlobalMouseUp);
  
    // クリーンアップ関数 イベントリスナーを適切に削除することで、メモリリークも防止
    return () => {
      window.removeEventListener('mousemove', handleGlobalMouseMove);
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isDragging, totalRotation, markers]);

    // マーカー生成
    const handleMarkerCreation = (latlng, image) => {
        // 既存のマーカーを削除
        if (Array.isArray(markers) && markers.length > 0) {
          markers.forEach((marker) => {
            mapRef.current.removeLayer(marker);
          });
          setMarkers([]); // 状態をリセット
        }
      
        // 新しいマーカーを作成 (Leaflet の divIcon を使う)
        const newMarker = L.marker(latlng, {
          icon: L.divIcon({
            className: 'custom-marker',
            html: `<div class="marker-icon"><div class="arrow"></div></div>`,
          }),
        }).addTo(mapRef.current);

        // 回転角度をリセット
        setTotalRotation(0);
      
        // 新しいマーカーを状態に保存
        setMarkers([newMarker]);
      
        // 地図の中心を更新
        setSelectedImage(image);
        setIsImageVisible(true);

        // 地図の描画エリアを再計算してから中心座標をリセット
        setTimeout(() => {
        if (mapRef.current) {
            mapRef.current.invalidateSize(); // 地図エリアを再計算
            mapRef.current.setView(latlng, mapRef.current.getZoom()); // 中心座標をリセット
        }
        }, 300); // アニメーションが完了する時間に合わせて調整

      };
      
      
  // イベントハンドラの登録
  useEffect(() => {
    if (mapRef.current) {
      // 青い円クリック時の処理
      circleLayers.forEach((circle) => {
        circle.off('click'); // 重複イベントを防止
        circle.on('click', () => {
            handleMarkerCreation(circle.getLatLng(), circle.options.image); // 円の中心座標を使用
          });          
      });
    }
  }, [circleLayers, markers]);
  

  // ストリートビューモードが切り替わったときに青い円を表示/削除
  useEffect(() => {
    if (mapRef.current) {
      // ストリートビューモード切り替え時に円を追加/削除
      circleLayers.forEach((layer) => mapRef.current.removeLayer(layer));
      setCircleLayers([]);

      if (isStreetView) {
        const newCircles = circlesData.map(({ lat, lng, radius, image }) => {
            const circle = L.circleMarker([lat, lng], {
              color: 'blue',
              fillColor: '#1E90FF',
              fillOpacity: 0.5,
              radius: 10, // ピクセル単位でサイズを指定
              image: image, // オプションに画像データを追加
            });
          
            circle.on('click', (e) => {
              handleMarkerCreation(e, circle.options.image); // オプションから画像を取得
            });
          
            circle.addTo(mapRef.current);
            return circle;
          });
          

        setCircleLayers(newCircles);
      }
    }
  }, [isStreetView]);

    // Closeボタンの処理
    const handleCloseImage = () => {
        setIsImageVisible(false);
        setSelectedImage(null);  // 画像をクリア

        // 地図の描画エリアを再計算してから中心座標をリセット
        setTimeout(() => {
          if (mapRef.current) {
            mapRef.current.invalidateSize();
            mapRef.current.setView(initialView, mapRef.current.getZoom()); // 必要なら初期位置に戻す
          }
        }, 300);
        
      };

  // タッチ対応かどうかをチェックして、適用するcameraコントロールを分ける
  useEffect(() => {
    const cameraEntity = document.getElementById("map-view-camera");

    if (cameraEntity) {
      const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);

      if (isTouchDevice) {
        cameraEntity.setAttribute("touch-look-controls", "");
      } else {
        cameraEntity.setAttribute(
          "look-controls",
          "reverseMouseDrag: true; magicWindowTrackingEnabled: false; touchEnabled: true; pointerLockEnabled: false"
        );
      }
    }
  }, [isImageVisible]); // 画像ビューが表示されたらカメラ設定
      

  return (
    <div className={`map-app-container ${isAnimating ? 'maphomeback' : ''}`}>

      {/* 360度画像（image-field）の中でドラッグを検出したいので、onMouse～ を仕込みます */}
      {isImageVisible && selectedImage && (
        <div
          className={`image-field ${isImageVisible ? 'slideanim' : ''}`}

          // ---------------------------
          // ★ ここにマウスイベントを追加
          // ---------------------------
          onMouseDown={handleMouseDown}
        >
          <button className="close-button" onClick={handleCloseImage}>✖</button>
          <a-scene
            ref={sceneRef}
            embedded
            loading-screen="enabled: false"
            vr-mode-ui="enabled: false"
            device-orientation-permission-ui="enabled: false"
          >
            <a-sky src={selectedImage} rotation="0 -90 0"></a-sky>
            <a-entity
              id="map-view-camera" 
              camera 
              position="0 1.6 0"
            ></a-entity>
          </a-scene>
        </div>
      )}

      <div className={`map-body ${isImageVisible ? 'with-image' : ''}`}>
        <div className="map-functions">
          <button className="street-view-toggle" onClick={handleStreetViewToggle}>
            {isStreetView ? '通常モード' : 'ストリートビュー'}
          </button>
          <button className="return-button" onClick={handleReturnToInitialView}>
            現在地に戻る
          </button>          
        </div>
        <div id="map" className={`map-view ${isImageVisible ? 'with-image' : ''}`}></div>
        {/* <div id="map" className="map-view"></div> */}
       </div> 

      <div className="map-footer">
        <button className="home-button" onMouseDown={handleClick}></button>
      </div>
    </div>
  );
};

export default MapApp;
