// FullScreenImage.js
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './FullScreenImage.css';
import './components/custom-look-controls';

export const BlackBlink = ({ type, onAnimationEnd }) => {
    useEffect(() => {
        let timer;
        if (type === 'closing') {
            timer = setTimeout(() => onAnimationEnd && onAnimationEnd(), 2000);
        } else if (type === 'opening') {
            timer = setTimeout(() => onAnimationEnd && onAnimationEnd(), 500);
        }
        return () => clearTimeout(timer);
    }, [type, onAnimationEnd]);

    return (
        <div className={`blackblink ${type}`}>
            <div className="blackblink-top"></div>
            <div className="blackblink-bottom"></div>
        </div>
    );
};

const FullScreenImage = ({ onClose, startAnimation = false, onOpeningAnimationStart }) => {
    const [blinkActive, setBlinkActive] = useState(false);
    const [blinkType, setBlinkType] = useState(null);
    const planeBRef = useRef(null);
    // const [backgroundSrc, setBackgroundSrc] = useState("https://d15k62eom0hyt7.cloudfront.net/360video/test.webp");
    const [backgroundSrc, setBackgroundSrc] = useState(`${process.env.PUBLIC_URL}/images/test2.webp`);
    const planeCRef = useRef(null);

    // openingアニメーション
    useEffect(() => {
        if (startAnimation) {
          setBlinkType('opening');
          setBlinkActive(true);
          // openingアニメーション開始時に音を再生
          const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/move.wav`);
          audio.play();
          onOpeningAnimationStart && onOpeningAnimationStart();
          const timer = setTimeout(() => setBlinkActive(false), 500);
          return () => clearTimeout(timer);
        }
      }, [startAnimation]);
      

    // クリック時の処理
    const handleClick = () => {
        setBlinkType('closing');
        setBlinkActive(true);
        setTimeout(() => {
            onClose && onClose();
        }, 1000);
    };

    // camera.jsと同様にaddEventListenerでイベント設定
    useEffect(() => {
        const plane = planeBRef.current;
        if (!plane) return;
        const clickHandler = handleClick;
        const mouseEnterHandler = () => {
            plane.setAttribute('material', 'color', 'red');
        };
        const mouseLeaveHandler = () => {
            plane.setAttribute('material', 'color', 'white');
        };
        plane.addEventListener('click', clickHandler);
        plane.addEventListener('mouseenter', mouseEnterHandler);
        plane.addEventListener('mouseleave', mouseLeaveHandler);
        return () => {
            plane.removeEventListener('click', clickHandler);
            plane.removeEventListener('mouseenter', mouseEnterHandler);
            plane.removeEventListener('mouseleave', mouseLeaveHandler);
        };
    }, []);

    useEffect(() => {
        const planeC = planeCRef.current;
        if (!planeC) return;
      
        const handlePlaneC_Click = () => {
          // closing アニメーション開始
          setBlinkType('closing');
          setBlinkActive(true);
          setTimeout(() => {
            // 背景画像を変更
            setBackgroundSrc(`${process.env.PUBLIC_URL}/images/test2.webp`);
            // opening アニメーション開始
            setBlinkType('opening');
            const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/move.wav`);
            audio.play();
            setBlinkActive(true);
            setTimeout(() => setBlinkActive(false), 500);
          }, 1000);
        };
      
        const mouseEnterHandler = () => {
          planeC.setAttribute('material', 'color', 'red');
        };
        const mouseLeaveHandler = () => {
          planeC.setAttribute('material', 'color', 'white');
        };
      
        planeC.addEventListener('click', handlePlaneC_Click);
        planeC.addEventListener('mouseenter', mouseEnterHandler);
        planeC.addEventListener('mouseleave', mouseLeaveHandler);
      
        return () => {
          planeC.removeEventListener('click', handlePlaneC_Click);
          planeC.removeEventListener('mouseenter', mouseEnterHandler);
          planeC.removeEventListener('mouseleave', mouseLeaveHandler);
        };
      }, []);
          

    const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);

    const overlay = (
        <div className="full-screen-image-container">
            {blinkActive && (
                <BlackBlink
                    type={blinkType}
                    onAnimationEnd={() => { /* 必要なら追加処理 */ }}
                />
            )}
            <a-scene
                embedded
                device-orientation-permission-ui="enabled: false"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: 9999,
                }}
            >
                <a-sky src={backgroundSrc} rotation="0 -90 0"></a-sky>
                <a-entity
                    id="cam"
                    camera="zoom: 1; active: true"
                    {...(isTouchDevice
                        ? { 'touch-look-controls': '' }
                        : { 'look-controls': 'reverseMouseDrag: true; magicWindowTrackingEnabled: false; touchEnabled: true; pointerLockEnabled: false' }
                    )}
                >
                    {/* カメラにa-cursorを追加することで、レイキャスターが有効になる */}
                    <a-cursor
                        color="yellow"
                        fuse="false"
                        fuse-timeout="500"
                        opacity="0.001"
                        visible="false"
                        rayOrigin="mouse"
                    ></a-cursor>
                </a-entity>
                <a-plane
                    ref={planeBRef}
                    id="phonePlaneB"
                    src={`${process.env.PUBLIC_URL}/images/like.png`}
                    position="1.5 -1.5 -3"
                    rotation="0 0 0"
                    width="2"
                    height="1"
                    material="side: double; transparent: true; alphaTest: 0.1;"
                    style={{ pointerEvents: 'auto' }}
                ></a-plane>
                <a-plane
                    ref={planeCRef}
                    id="planeC"
                    src={`${process.env.PUBLIC_URL}/images/nope.png`}
                    position="2 1.5 -3"  // 適宜位置調整
                    rotation="0 0 0"
                    width="2"
                    height="1"
                    material="side: double; transparent: true; alphaTest: 0.1;"
                    style={{ pointerEvents: 'auto' }}
                ></a-plane>

            </a-scene>
        </div>
    );

    return ReactDOM.createPortal(overlay, document.body);
};

export default FullScreenImage;
